export default [
    {
        path: "/system",
        component: () => import("@/view/system/index"),
        redirect: "/system/entrance",
        name: "system",
        meta: {
            title: "系统管理"
        },
        children: [
            {
                path: "entrance",
                name: "system-entrance",
                component: () => import("@/view/system/entrance"),
                meta: {
                    title: "入口链接"
                }
            },
            {
                path: "tabbar",
                name: "shopSystemTabbar",
                component: () => import("@/view/system/tabbar"),
                meta: {
                    title: "自定义菜单"
                }
            },
            {
                path: "wechat",
                name: "system-wechat",
                component: () => import("@/view/system/wechat/index"),
                meta: {
                    title: "微信对接"
                },
                redirect: "/system/wechat/mini",
                children:[
                    {
                        path:"mini",
                        component:()=>import("@/view/system/wechat/mini"),
                        meta:{
                            title:"微信小程序"
                        }
                    },
                    {
                        path:"official",
                        component:()=>import("@/view/system/wechat/official"),
                        meta:{
                            title:"微信公众号"
                        }
                    },
                    {
                        path:"pay",
                        component:()=>import("@/view/system/wechat/pay"),
                        meta:{
                            title:"微信支付"
                        }
                    },
                ]
            },
            {
                path: "ali-pay",
                name: "system-ali-pay",
                component: () => import("@/view/system/aliPay"),
                meta: {
                    title: "阿里支付"
                }
            },
            {
                path: "attach",
                name: "system-attach",
                component: () => import("@/view/system/attach/index"),
                meta: {
                    title: "附件管理"
                }
            },
            {
                path: "setting",
                name: "system-setting",
                component: () => import("@/view/system/setting"),
                meta: {
                    title: "基础配置"
                }
            },
            {
                path: "sms",
                name: "system-sms",
                component: () => import("@/view/system/sms"),
                meta: {
                    title: "短信配置"
                }
            },
        ]
    }
]