import article from "@/router/album/article";

export default [
    {
        path: "/album",
        component: () => import("@/view/album/index"),
        redirect: "/album/manage",
        name: "album",
        meta: {
            title: "画册"
        },
        children: [
            {
                path: "manage",
                name: "album-manage",
                component:()=> import("@/view/album/manage/index"),
                redirect: "/album/manage/list",
                meta: {
                    title: "画册管理"
                },
                children: [
                    {
                        path: "cate",
                        component:() => import("@/view/album/manage/cate"),
                        meta: {
                            title: "画册分类"
                        },
                    },
                    {
                        path: "list",
                        component:() => import("@/view/album/manage/list"),
                        meta: {
                            title: "画册列表"
                        },
                    },
                    {
                        path: "edit",
                        name: "album-manage-edit",
                        component:() => import("@/view/album/manage/edit"),
                        meta: {
                            title: "画册编辑"
                        },
                    },
                    {
                        path: "img-list",
                        name: "album-manage-img-list",
                        component:() => import("@/view/album/manage/img-list"),
                        meta: {
                            title: "画册图片管理"
                        },
                    },
                ]
            },
            {
                path: "video",
                name: "album-video",
                component: ()=> import("@/view/album/video/index"),
                redirect: "/album/video/manage",
                meta: {
                    title: "视频管理"
                },
                children: [
                    {
                        path: "manage",
                        component:()=>import("@/view/album/video/manage"),
                        meta: {title: "视频列表"}
                    }
                ]
            },
            article,
            {
                path: "system",
                name: "album-system",
                component:()=> import("@/view/album/setting/index"),
                redirect: "/album/system/basic",
                meta: {
                    title: "画册配置"
                },
                children: [
                    {
                        path: "poster",
                        component:() => import("@/view/album/setting/poster"),
                        meta: {
                            title: "画册海报"
                        },
                    },
                    {
                        path: "banner",
                        component:() => import("@/view/album/setting/banner"),
                        meta: {
                            title: "画册首页轮播图"
                        },
                    },
                    {
                        path: "basic",
                        component: () => import("@/view/album/setting/basic"),
                        meta: {
                            title: "基础配置"
                        },
                    }
                ]
            },
        ]
    }
]