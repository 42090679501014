export default [
    {
        path: "/website",
        component: () => import("@/view/website/index"),
        redirect: "/website/index-banner",
        name: "website",
        meta: {
            title: "官网"
        },
        children: [
            {
                path: "index-banner",
                name: "website-index-banner",
                component: () => import("@/view/website/indexBanner"),
                meta: {
                    title: "首页轮播"
                }
            },
            {
                path: "index-conf",
                name: "website-index-conf",
                component: () => import("@/view/website/indexConf"),
                meta: {
                    title: "首页配置"
                }
            },
            {
                path: "top-nav",
                name: "website-top-nav",
                component: () => import("@/view/website/topNav"),
                meta: {
                    title: "顶部导航"
                }
            },
            {
                path: "bottom-nav",
                name: "website-bottom-nav",
                component: () => import("@/view/website/bottomNav"),
                meta: {
                    title: "底部外链"
                }
            },
            {
                path: "setting",
                name: "website-setting",
                component: () => import("@/view/website/setting"),
                meta: {
                    title: "基础配置"
                }
            },
        ]
    }
]