import Vue from 'vue';
import App from './App.vue';
import './plugins/element.js';

Vue.config.productionTip = false;

import axios from '@/api/axios';
import VueAxios from 'vue-axios';

Vue.use(VueAxios, axios);

import VueRouter from 'vue-router';
Vue.use(VueRouter);
import router from "@/router";
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

import libU from "@/common/lib/$u";
Vue.use(libU)
import store from '@/store';
import api from "@/api";

import Vue2OrgTree from 'vue2-org-tree'
Vue.use(Vue2OrgTree)

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')

api.globalSet();