export default {
    path: "goods",
    component: () => import("@/view/shop/goods/index"),
    redirect: "/shop/goods/manage",
    name: "shop-goods",
    meta: {
        title: "商品库"
    },
    children: [
        {
            path: "manage",
            name: "shopGoodsManage",
            component: () => import("@/view/shop/goods/manage"),
            meta: {
                title: "商品管理"
            }
        },
        {
            path: "edit",
            name: "shopGoodsEdit",
            component: () => import("@/view/shop/goods/edit"),
            meta: {
                title: "商品编辑"
            }
        },
        {
            path: "upgrade-log",
            name: "shopGoodsUpgradeLog",
            component: () => import("@/view/shop/goods/upgradeLog/manage"),
            meta: {
                title: "商品更新记录"
            }
        },
        {
            path: "upgrade-log-edit",
            name: "shopGoodsUpgradeLogEdit",
            component: () => import("@/view/shop/goods/upgradeLog/edit"),
            meta: {
                title: "商品更新记录编辑"
            }
        },
        {
            path: "category",
            name: "shopGoodsCategory",
            component: () => import("@/view/shop/goods/category"),
            meta: {
                title: "分类管理"
            }
        },
        {
            path:"theme",
            name: "shopGoodsTheme",
            component: ()=>import("@/view/shop/goods/theme/list"),
            meta: {
                title: "商品专题"
            }
        },
        {
            path:"shipping",
            name: "shopGoodsShipping",
            component: ()=>import("@/view/shop/goods/shipping/list"),
            meta: {
                title: "运费模版"
            }
        },
        {
            path:"shipping-items",
            name: "shopGoodsShippingItems",
            component: ()=>import("@/view/shop/goods/shipping/edit"),
            meta: {
                title: "运费模版编辑"
            }
        },
        {
            path:"import",
            name: "shopGoodsImport",
            component: ()=>import("@/view/shop/goods/import"),
            meta: {
                title: "快速导入淘宝商品"
            }
        },
    ]
}