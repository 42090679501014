import {Message} from "element-ui";
import router from "@/router";
import {rememberLogin} from "@/common/util";

export async function responseParse (result){
    if (result.data.errno !== 0){
        if (result.data.message)Message.info(result.data.message);
        switch (result.data.errno){
            case 2:
                if (result.data.data && router.currentRoute.path !== result.data.data)router.push(result.data.data)
                break;
            case 40019:
                await rememberLogin()
                break;
        }
        return Promise.reject(result.data);
    }
    if (result.data.message)Message.info(result.data.message);
    return result.data.data;
}