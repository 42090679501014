import article from "@/api/album/article";
import config from "@/config";
import Vue from "vue";

const base = config.api_base + "/album";

export default {
    bannerAll: (params) => Vue.axios.post(base + "/banner-all", params),
    bannerEdit: (params) => Vue.axios.post(base + "/banner-edit", params),
    bannerDel: (params) => Vue.axios.post(base + "/banner-del", params),

    cateAll: (params) => Vue.axios.post(base + "/cate-all", params),
    cateEdit: (params) => Vue.axios.post(base + "/cate-edit", params),
    cateDel: (params) => Vue.axios.post(base + "/cate-del", params),

    del: (params) => Vue.axios.post(base + "/del", params),
    edit: (params) => Vue.axios.post(base + "/edit", params),
    one: (params) => Vue.axios.post(base + "/one", params),
    search: (params) => Vue.axios.post(base + "/search", params),

    imgDel: (params) => Vue.axios.post(base + "/img-del", params),
    imgEdit: (params) => Vue.axios.post(base + "/img-edit", params),
    imgSearch: (params) => Vue.axios.post(base + "/img-search", params),
    imgPdfImportAction: base + "/img-pdf-import",

    poster: (params) => Vue.axios.post(base + "/poster", params),
    posterEdit: (params) => Vue.axios.post(base + "/poster-edit", params),
    posterClear: (params) => Vue.axios.post(base + "/poster-clear", params),

    setting: (params) => Vue.axios.post(base + "/setting", params),
    settingEdit: (params) => Vue.axios.post(base + "/setting-edit", params),

    videoEdit: (params) => Vue.axios.post(base + "/video-edit", params),
    videoDel: (params) => Vue.axios.post(base + "/video-del", params),
    videoSearch: (params) => Vue.axios.post(base + "/video-search", params),

    article,
}