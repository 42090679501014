export default [
    {
        path: "/account",
        component: () => import("@/view/account/index"),
        redirect: "/account/info",
        name: "account",
        meta: {
            title: "账户"
        },
        children: [
            {
                path: "info",
                name: "account-info",
                component: () => import("@/view/account/info"),
                meta: {
                    title: "账户信息"
                }
            },
            {
                path: "login",
                name: "account-login",
                component:()=>import("@/view/account/login"),
                meta: {
                    title: "登录"
                }
            }
        ]
    }
]