import config from "@/config";
import Vue from "vue";

const base = config.api_base + "/card";

export default {
    staffSearch: (params) => Vue.axios.post(base + "/staff-search", params),
    staffEdit: (params) => Vue.axios.post(base + "/staff-edit", params),
    staffOne: (params) => Vue.axios.post(base + "/staff-one", params),
    staffDel: (params) => Vue.axios.post(base + "/staff-del", params),

    departmentAll: (params) => Vue.axios.post(base + "/department-all", params),
    departmentEdit: (params) => Vue.axios.post(base + "/department-edit", params),
    departmentDel: (params) => Vue.axios.post(base + "/department-del", params),

    positionAll: (params) => Vue.axios.post(base + "/position-all", params),
    positionEdit: (params) => Vue.axios.post(base + "/position-edit", params),
    positionDel: (params) => Vue.axios.post(base + "/position-del", params),

    scriptCateCustomAll: (params) => Vue.axios.post(base + "/script-cate-custom-all", params),
    scriptCateSysAll: (params) => Vue.axios.post(base + "/script-cate-sys-all", params),
    scriptCateSysEdit: (params) => Vue.axios.post(base + "/script-cate-sys-edit", params),
    scriptCateSysDel: (params) => Vue.axios.post(base + "/script-cate-sys-del", params),

    scriptItemCustomAll: (params) => Vue.axios.post(base + "/script-item-custom-all", params),
    scriptItemSysAll: (params) => Vue.axios.post(base + "/script-item-sys-all", params),
    scriptItemSysEdit: (params) => Vue.axios.post(base + "/script-item-sys-edit", params),
    scriptItemSysDel: (params) => Vue.axios.post(base + "/script-item-sys-del", params),

    setting: (params) => Vue.axios.post(base + "/setting", params),
    settingEdit: (params) => Vue.axios.post(base + "/setting-edit", params),
}