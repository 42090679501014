import Vue from "vue";
import config from "@/config";

const goods = {};
const base = config.api_base + "/shop/goods"

goods.cateAll = (params)=>Vue.axios.post( base + "/cate-all",params);
goods.cateEdit = (params)=>Vue.axios.post( base + "/cate-edit",params);
goods.cateAdd = (params)=>Vue.axios.post( base + "/cate-add",params);
goods.cateDel = (params)=>Vue.axios.post( base + "/cate-del",params);


goods.importTaoGoods = (params)=>Vue.axios.post( base + "/import-tao-goods",params);

goods.search = (params)=>Vue.axios.post( base + "/search",params);
goods.edit = (params)=>Vue.axios.post( base + "/edit",params);
goods.del = (params)=>Vue.axios.post( base + "/del",params);
goods.one = (params)=>Vue.axios.post( base + "/one",params);

goods.specSearch = (params)=>Vue.axios.post( base + "/spec-search",params);
goods.specEdit = (params)=>Vue.axios.post( base + "/spec-edit",params);
goods.specDel = (params)=>Vue.axios.post( base + "/spec-del",params);

goods.specItemEdit = (params)=>Vue.axios.post( base + "/spec-item-edit",params);
goods.specItemDel = (params)=>Vue.axios.post( base + "/spec-item-del",params);
goods.specOption = (params)=>Vue.axios.post( base + "/spec-option",params);
goods.specOptionEdit = (params)=>Vue.axios.post( base + "/spec-option-edit",params);
goods.specOptionEditGlobal = (params)=>Vue.axios.post( base + "/spec-option-edit-global",params);
goods.specOptionRefresh = (params)=>Vue.axios.post( base + "/spec-option-refresh",params);

goods.themeSearch = (params)=>Vue.axios.post( base + "/theme-search",params);
goods.themeEdit = (params)=>Vue.axios.post( base + "/theme-edit",params);
goods.themeDel = (params)=>Vue.axios.post( base + "/theme-del",params);

goods.shippingEdit = (params)=>Vue.axios.post( base + "/shipping-edit",params);
goods.shippingOne = (params)=>Vue.axios.post( base + "/shipping-one",params);
goods.shippingSearch = (params)=>Vue.axios.post( base + "/shipping-search",params);
goods.shippingDel = (params)=>Vue.axios.post( base + "/shipping-del",params);

goods.shippingItemAll = (params)=>Vue.axios.post( base + "/shipping-item-all",params);
goods.shippingItemEdit = (params)=>Vue.axios.post( base + "/shipping-item-edit",params);
goods.shippingItemDel = (params)=>Vue.axios.post( base + "/shipping-item-del",params);

goods.inviteRule = (params)=>Vue.axios.post( base + "/invite-rule",params);
goods.inviteRuleEdit = (params)=>Vue.axios.post( base + "/invite-rule-edit",params);

goods.upgradeLog = (params)=>Vue.axios.post( base + "/upgrade-log",params);
goods.upgradeLogEdit = (params)=>Vue.axios.post( base + "/upgrade-log-edit",params);
goods.upgradeLogOne = (params)=>Vue.axios.post( base + "/upgrade-log-one",params);
goods.upgradeLogDel = (params)=>Vue.axios.post( base + "/upgrade-log-del",params);



export default goods;