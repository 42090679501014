export default {
    path: "finance",
    component: () => import("@/view/shop/finance/index"),
    redirect: "/shop/finance/wait",
    name: "shopFinance",
    meta: {
        title: "财务"
    },
    children: [
        {
            path: "wait",
            name: "shopFinanceWait",
            component: () => import("@/view/shop/finance/wait"),
            meta: {
                title: "待打款"
            }
        },
        {
            path: "log",
            name: "shopFinanceLog",
            component: () => import("@/view/shop/finance/log"),
            meta: {
                title: "打款记录"
            }
        }
    ]
}
