import config from "@/config";
import Vue from "vue";

const base = config.api_base + "/website";

export default {
    bannerAll: (params) => Vue.axios.post(base + "/banner-all", params),
    bannerEdit: (params) => Vue.axios.post(base + "/banner-edit", params),
    bannerDel: (params) => Vue.axios.post(base + "/banner-del", params),

    bottomNavGroupAll: (params) => Vue.axios.post(base + "/bottom-nav-group-all", params),
    bottomNavGroupEdit: (params) => Vue.axios.post(base + "/bottom-nav-group-edit", params),
    bottomNavGroupDel: (params) => Vue.axios.post(base + "/bottom-nav-group-del", params),
    bottomNavItemAll: (params) => Vue.axios.post(base + "/bottom-nav-item-all", params),
    bottomNavItemEdit: (params) => Vue.axios.post(base + "/bottom-nav-item-edit", params),
    bottomNavItemDel: (params) => Vue.axios.post(base + "/bottom-nav-item-del", params),

    indexConf: (params) => Vue.axios.post(base + "/index-conf", params),
    indexConfEdit: (params) => Vue.axios.post(base + "/index-conf-edit", params),

    setting: (params) => Vue.axios.post(base + "/setting", params),
    settingEdit: (params) => Vue.axios.post(base + "/setting-edit", params),

    topNavAll: (params) => Vue.axios.post(base + "/top-nav-all", params),
    topNavEdit: (params) => Vue.axios.post(base + "/top-nav-edit", params),
    topNavDel: (params) => Vue.axios.post(base + "/top-nav-del", params),
}