import goods from "@/router/shop/goods";
import system from "@/router/shop/system";
import order from "@/router/shop/order";
import finance from "@/router/shop/finance";

export default [
    {
        path: "/shop",
        component: () => import("@/view/shop/index"),
        redirect: "/shop/dashboard",
        name: "shop",
        meta: {
            title: "商城"
        },
        children: [
            {
                path: "dashboard",
                name: "shop-dashboard",
                component: () => import("@/view/shop/dashboard/index"),
                meta: {
                    title: "概览"
                }
            },
            goods,
            system,
            order,
            finance,
        ]
    }
]