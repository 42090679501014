export default [
    {
        path: "/card",
        component: () => import("@/view/card/index"),
        redirect: "/card/staff",
        name: "card",
        meta: {
            title: "名片"
        },
        children: [
            {
                path: "setting",
                name: "card-setting",
                component: () => import("@/view/card/setting/basic"),
                meta: {
                    title: "基础配置"
                }
            },
            {
                path: "staff",
                name: "card-staff",
                component: () => import("@/view/card/staff/index"),
                meta: {
                    title: "员工管理"
                },
                redirect: "/card/staff/list",
                children: [
                    {
                        path: "list",
                        name: "card-staff-list",
                        component: () => import("@/view/card/staff/manage"),
                        meta: {
                            title: "员工列表"
                        },
                    },
                    {
                        path: "edit",
                        name: "card-staff-edit",
                        component: () => import("@/view/card/staff/edit"),
                        meta: {
                            title: "员工编辑"
                        },
                    }
                ]
            },
            {
                path: "script-custom",
                name: "card-script-custom",
                component: () => import("@/view/card/script-custom/index"),
                redirect: "/card/script-custom/list",
                meta: {
                    title: "员工自定义话术库"
                },
                children: [
                    {
                        path: "list",
                        component: () => import("@/view/card/script-custom/list"),
                        meta: {
                            title: "员工自定义话术列表"
                        },
                    },
                    {
                        path: "cate",
                        component: () => import("@/view/card/script-custom/cate"),
                        meta: {
                            title: "员工自定义话术分类"
                        },
                    }
                ]
            },
            {
                path: "script-sys",
                name: "card-script-sys",
                component: () => import("@/view/card/script-sys/index"),
                redirect: "/card/script-sys/list",
                meta: {
                    title: "公共话术库"
                },
                children: [
                    {
                        path: "list",
                        component: () => import("@/view/card/script-sys/list"),
                        meta: {
                            title: "公共话术列表"
                        },
                    },
                    {
                        path: "cate",
                        component: () => import("@/view/card/script-sys/cate"),
                        meta: {
                            title: "公共话术分类"
                        },
                    }
                ]
            },
            {
                path: "department",
                name: "card-department",
                component: () => import("@/view/card/department/index"),
                redirect: "/card/department/manage",
                meta: {
                    title: "部门/职位"
                },
                children: [
                    {
                        path: "manage",
                        component: () => import("@/view/card/department/manage"),
                        meta: {
                            title: "部门管理"
                        },
                    },
                    {
                        path: "position",
                        name: "card-department-position",
                        component: () => import("@/view/card/department/position"),
                        meta: {
                            title: "部门下属职位管理"
                        },
                    },
                ]
            },
        ]
    }
]