import account from "@/api/account";
import Vue from "vue";
import {vuex} from "@/common/util";
import attach from "@/api/attach";
import founder from "@/api/founder";
import shop from "@/api/shop";
import user from "@/api/user";
import system from "@/api/system";
import album from "@/api/album";
import card from "@/api/card";
import website from "@/api/website";
export default {
    founder,
    account,
    album,
    card,
    attach,
    shop,
    user,
    system,
    website,
    globalSet: ()=> {
        Vue.axios.get("/app/global-set").then(res=>{
            vuex("vuex_global_set",res);
        })
    }
}

