import {formatDate, login, toMedia, vuex} from "@/common/util";
import vuexStore from "@/common/lib/$u.mixin";
import api from "@/api";
import router from "@/router";
const $u = {
    date: formatDate,
    api,
    login,
    toMedia,
    formatDate,
    toFounder:()=>{
        vuex("vuex_active_uniacid",0);
        router.push({name:'founder-uni'});
    },
    toUni:(uniacid)=>{
        vuex("vuex_active_uniacid",uniacid);
        router.push("/user/all");
    }
}


const install = Vue => {

    Vue.mixin(vuexStore);
    Vue.filter('date', (timestamp) => {
        return formatDate(timestamp)
    })
    Vue.filter("tomedia", (value) => {
        return  toMedia(value)
    })
    Vue.prototype.$u = $u
}

export default {
    install
}