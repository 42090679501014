export default [
    {
        path: "/user",
        component: () => import("@/view/user/index"),
        redirect: "/user/visitor",
        name: "user",
        meta: {
            title: "用户管理"
        },
        children: [
            {
                path: "visitor",
                name: "user-visitor",
                component: () => import("@/view/user/visitor"),
                meta: {
                    title: "匿名访客"
                }
            },
            {
                path: "member",
                name: "user-member",
                component: () => import("@/view/user/member"),
                meta: {
                    title: "授权用户"
                }
            },
            {
                path: "connect",
                name: "user-connect",
                component: () => import("@/view/user/connect"),
                meta: {
                    title: "沟通用户"
                }
            },
            {
                path: "all",
                name: "user-all",
                component: () => import("@/view/user/all"),
                meta: {
                    title: "全部用户"
                }
            },
        ]
    }
]