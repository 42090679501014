import axios from "axios";
import store from "@/store";
import config from "@/config";
import {Message} from "element-ui";
import {responseParse} from "@/common/response";
import {requestWithUni} from "@/common/resquest";

axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? location.origin : config.dev_base;
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

axios.interceptors.request.use(async function (config) {
    config.headers.ytoken = store.state.vuex_token.data;
    config.url = requestWithUni(config.url);
    return config;
}, function (error) {
    Message.error("网络通信异常")
    return Promise.reject(error)
})

axios.interceptors.response.use(async function (response) {
    return responseParse(response);
}, function (error) {
    Message.error("响应异常");
    return Promise.reject(error)
})


export default axios;